import api from "../axios";
import { baseUrl } from "../baseUrl";

export default {
  // 列表
  list(params) {
    return api.get(baseUrl + 'api/school/invite/code/list',params)
  },
  // 新增
  add(params) {
    return api.post(baseUrl + 'api/school/invite/code/add', params)
  },
  // 修改
  update(params) {
    return api.post(baseUrl + `api/school/invite/code/update/${params.id}`, params)
  },
  del(params) {
    return api.post(baseUrl + `api/school/invite/code/delete/${params.id}`)
  },
  // 新增双选会邀请函
  addsxh(params) {
    return api.post(baseUrl + 'api/sxh/invite/code/add', params)
  },
};