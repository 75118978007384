// 企业管理
import api from "../axios";
import { baseUrl } from "../baseUrl";

export default {
  // 企业统计数据
  getStatisticData() {
    return api.get(`${baseUrl}api/school/static/main`)
  },
  getNotice() {
    return api.get(`${baseUrl}api/school/enterprise/indexData`)
  },
  // 企业申请审核列表
  applyList(params) {
    return api.post(baseUrl + "api/school/enterprise/list", params);
  },
  // 企业申请审核
  apply(params) {
    return api.getParams(baseUrl + "api/school/enterprise/audit", params);
  },
  // 企业统计汇总
  totalList(params) {
    return api.post(baseUrl + "api/school/enterprise/xixun/list", params);
  },
  // // 企业申请审核详情
  // applyDetail(params) {
  //   return api.get(baseUrl + "api/enterprise/business/info/update", params);
  // },
  // 意向列表
  getIntention(params) {
    return api.post(baseUrl + "api/school/enterprise/intention/list", params)
  },
  auditIntention(params) {
    return api.post(baseUrl + "api/school/enterprise/intention/audit", params)
  },
  smsCollect(relation_id) {
    return api.post(baseUrl + `api/school/sms/collect`, {
      relation_id: relation_id
    })
  },
  deleteEnterprise(record_id) {
    return api.delete(baseUrl + `api/school/enterprise/record/delete/${record_id}`)
  },
  editEnterprise(params) {
    return api.post(`${baseUrl}api/school/enterprise/record/update/${params.id}`, params)
  },
  tagEnterprise(params) {
    return api.post(`${baseUrl}api/school/enterprise/tag/update`, params)
  },
  associateMajors(params) {
    return api.post(`${baseUrl}api/school/enterprise/major/add`, params)
  },
  cancelAssociateMajor(relationId) {
    return api.delete(`${baseUrl}api/school/enterprise/major/delete/${relationId}`)
  },
  getAssociationMajors(enterpriseId) {
    return api.get(`${baseUrl}api/school/enterprise/enterprise_major/get/${enterpriseId}`)
  },
  // 获取企业信息
  getCompanyInfo(enterpriseId) {
    return api.get(`${baseUrl}api/school/enterprise/info/${enterpriseId}`)
  },
  // 下载企业注册信息
  downCompany(params) {
    return api.get(`${baseUrl}api/school/enterprise/list/download`,params)
  },
  //获取企业配置
  geteEnterpriseConfig(params){
    return api.get(`${baseUrl}api/school/sxh/institute/enterprise/config/list`,params)
  },
  //获取企业
  getEnterpruseList(params){
    return api.post(`${baseUrl}api/school/sxh/enterprise/list`,params)
  },
  //保存企业配置
  saveEnterpruseConf(params){
    return api.post(`${baseUrl}api/school/sxh/institute/enterprise/config`,params)
  },
  //获取企业执照
  getEnterpriseImg(enterprise_id,type){
    return api.get(`${baseUrl}api/vanber/enterprise/pic/get/${enterprise_id}/${type}`)
  }
};