import api from "../axios";
import {baseUrl} from "../baseUrl";

export default {
  register(params) {
    return api.post(baseUrl + "api/enterprise/register", params);
  },
  // 发送验证码
  sendCode(params) {
    return api.getParams(baseUrl + "api/app/id_code/send", params);
  },
  // 获取企业行业信息
  getTrade() {
    return api.get(baseUrl + "api/sys/list/trade");
  },
  // 获取省级列表
  getProvince() {
    return api.get(baseUrl + "api/common/province/list");
  },
  // 添加补充信息
  addInfo(params) {
    return api.post(baseUrl + "api/enterprise/business/info/add", params);
  },
  // 获取补充信息详情
  getInfo() {
    return api.get(baseUrl + "api/enterprise/business/info/get");
  },
  // 更新补充信息
  updateInfo(params) {
    return api.post(baseUrl + "api/enterprise/business/info/update", params);
  }
};