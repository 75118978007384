import register from "./api_modules/register";
import login from "./api_modules/login";
import school from "./api_modules/school";
import cooperation from "./api_modules/cooperation";
import type from "./api_modules/type";
import job from "./api_modules/job";
import invite from "./api_modules/invite";
import enInvited from "./api_modules/enInvited";
import enManage from "./api_modules/enManage";
import common from "./api_modules/common";

export default {
  register,
  login,
  job,
  enManage,
  cooperation,
  type,
  school,
  invite,
  enInvited,
  common
};
