import Vue from "vue";
import Vuex from "vuex";
import api from "./api/apiList";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountInfo: {},
    register: {},
    fileUrl: 'https://oss-resume.xixunyun.com/',
    fileUrl2: 'https://api.qxiaoyun.com/',
    companySourceNew: [
      { label: '学生邀请', value: '1' },
      { label: '学生推荐', value: '2' },
      { label: '企业联系', value: '3' },
      { label: '其他', value: '4' },
      { label: '双选会', value: '5' },
      // { label: '学校发布', value: 'xixun' },
      // { label: '云南', value: 'yunnan' },
    ],
    baseTypes: ['全部', '教学实习基地', '产学研合作基地', '创新创业实践基地', '现代产业学院', '其他（如：单纯捐赠）'],
    companyType: [
      { label: '未知', value: '0' },
      { label: '外资（欧美）', value: '1' },
      { label: '外资（非欧美）', value: '2' },
      { label: '合资', value: '3' },
      { label: '民营公司', value: '4' },
      { label: '上市公司', value: '5' },
      { label: '创业公司', value: '6' },
      { label: '外企代表处', value: '7' },
      { label: '政府机关', value: '8' },
      { label: '事业单位', value: '9' },
      { label: '非营利机构', value: '10' },
      { label: '国企', value: '11' },

    ],
    companySource: [
      { label: '全部', value: '' },
      { label: '学校联系', value: '1' },
      { label: '学校邀请', value: 'school' },
      { label: '万博邀请', value: 'vanber' },
      { label: '自主注册', value: 'enterprise' },
      { label: '学校发布', value: 'school_self' },
      { label: '双选会邀请', value: 'sxh' },
      // { label: '学校发布', value: 'xixun' },
      // { label: '云南', value: 'yunnan' },
    ],
    instituteObj: {},
    majors: [],
    departments: [],
    allDepartments: [],
    foods: null,
    jobStatus: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '开启',
        value: '1'
      },
      {
        label: '关闭',
        value: '0'
      },
    ],
    // jobStatus: [
    //   {
    //     label: '全部',
    //     value: ''
    //   },
    //   {
    //     label: '开启',
    //     value: 'valid'
    //   },
    //   {
    //     label: '关闭',
    //     value: 'offline'
    //   },
    // ],
    trades: {},
    companyTag: [
      { label: '全部', value: '' },
      { label: '优质实习单位', value: 'quality' },
      // { label: '首次合作企业', value: 'yes'},
      { label: '就业单位', value: 'star' },
    ],
    companyFirst: [
      { label: '全部', value: '' },
      // { label: '优质企业', value: 'quality'},
      { label: '首次合作企业', value: 'yes' },
    ],
  },
  getters: {
    getMajors(state) {
      return state.majors
    },
    getFoods(state) {
      return state.foods
    },
    getJobStatus(state) {
      return state.jobStatus
    }
  },
  mutations: {
    // 注册
    reg(state) {
      state.register = form;
    },
    setAccountInfo(state, payload) {
      state.accountInfo = payload
    },
    setMajors(state, payload) {
      state.majors = payload
    },
    seDepartments(state, payload) {
      state.departments = payload
    },
    seAllDepartments(state, payload) {
      state.allDepartments = payload
    },
    setFoods(state, payload) {
      state.foods = payload
    },
    setTrades(state, payload) {
      state.trades = payload
    },
    setProvinces(state, payload) {
      state.provinces = payload
    },
    setInstituteObj(state, payload) {
      state.instituteObj = payload
    },
  },
  actions: {
    async getProvinces(context) {
      let res = await api.common.getXixunProvince();
      if (res.code === 0) {
        context.commit('setProvinces', res.data)
      }
    },
    // 专业
    async getMajors(context) {
      let res = await api.school.getMajorList()
      if (res.code === 0) {
        context.commit('setMajors', res.data)
      }
    },
    // 院系
    async getDepartments(context, param) {
      let res = await api.common.getDataByUrl(`api/v4/schoolToken/School/departs`, 'get', { school_id: param.school_id })
      if (res.code === 0) {
        context.commit('seDepartments', res.data)
        if (res.data && res.data.length && param.callback) {
          param.callback(res.data[0].id)
        }
      }
    },
    async getAllDepartments(context, param) {
      if (context.state.allDepartments.length == 0) {
        let res = await api.common.getDataByUrl(`api/school/public/departs`, 'get', { school_id: param })
        if (res.code === 0) {
          context.commit('seAllDepartments', res.data)
        }
      }
    },
    // 食宿
    async getFoods(context) {
      let res = await api.type.getType("food_bed")
      if (res.code === 0) {
        context.commit('setFoods', res.data)
      }
    },
    // 行业类型
    async getTrades(context) {
      let res = await api.type.getTrade();
      if (res.code === 0) {
        context.commit('setTrades', res.data)
      }
    },
  }
});
