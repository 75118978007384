import api from "../axios";
import { baseUrl } from "../baseUrl";

export default {
  //免登陆
  nologin(params) {
    let urll = baseUrl
    if (params.school_id == 2183) {
      urll = 'https://sx.tec.suda.edu.cn/api/qxyapi/'
    }
    return api.post(urll + "api/school/login/direct", params);
  },
  // 登录
  login(params) {
    return api.post(baseUrl + "api/school/login", params);
  },
  //登出
  logout(params) {
    return api.get(baseUrl + "api/school/logout", params);
  },
};