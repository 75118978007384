import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/login",
      name: "登录",
      component: () => import("./views/Login.vue")
    },
    {
      path: "/",
      component: () => import("./views/Nav"),
      children: [
        {
          path: "/",
          alias: '/index',
          component: () => import("./views/Index"),
          name: "主页", meta: { requireAuth: true }
        },
        // 学校管理
        {
          name: '基本信息',
          path: '/base-info',
          component: () => import('./views/school/base-info/base-info.vue')
        },
        {
          name: '中转页',
          path: '/skip',
          component: () => import('./views/skip.vue')
        },
        // 企业管理
        // {
        //   path: "/en-inviteList",
        //   component: () => import("./views/en-manage/en-inviteList"),
        //   name: "企业注册邀请",
        //   meta: { requireAuth: true }
        // },
        {
          path: "/en-inviteList",
          component: () => import("./views/en-manage/en-inviteListAll"),
          name: "企业注册邀请",
          meta: { requireAuth: true }
        },
        {
          path: "/en-applyList",
          component: () => import("./views/en-manage/audit/en-applyList"),
          name: "企业申请审核",
          meta: { requireAuth: true }
        },
        {
          path: "/audit-intention",
          component: () => import("./views/en-manage/audit/audit-intention"),
          name: "审核意向",
          meta: { requireAuth: true }
        },
        {
          path: "/en-infoList",
          component: () => import("./views/en-manage/en-infoList"),
          name: "企业信息管理",
          meta: { requireAuth: true }
        },
        {
          path: "/en-student-infoList",
          component: () => import("./views/en-manage/en-student-infoList"),
          name: "企业信息管理",
          meta: { requireAuth: true }
        },
        {
          name: "优质企业",
          path: '/quality-company',
          component: () => import("./views/en-manage/quality-company"),
        },
        {
          path: "/en-stuList",
          component: () => import("./views/en-manage/en-stuList"),
          name: "学生分布情况",
          meta: { requireAuth: true }
        },
        {
          path: "/en-feedbacks",
          component: () => import("./views/en-manage/en-feedbacks"),
          name: "企业反馈列表",
          meta: { requireAuth: true }
        },
        {
          path: "/en-totalList",
          component: () => import("./views/en-manage/en-totalList"),
          name: "企业统计汇总",
          meta: { requireAuth: true }
        },
        {
          path: "/enList",
          component: () => import("./views/job/enList"),
          meta: { requireAuth: true }
        },
        {
          path: "/jobAdd/:id",
          component: () => import("./views/job/jobAdd"),
          name: "岗位发布",
          meta: { requireAuth: true }
        },
        {
          path: "/checkCompany",
          component: () => import("./views/job/checkCompany"),
          name: "实习单位考察",
          meta: { requireAuth: true }
        },
        {
          path: "/schjobList",
          component: () => import("./views/job/schjobList"),
          name: "学校发布岗位列表",
          meta: { requireAuth: true }
        },
        {
          path: "/enterpriseLegal",
          component: () => import("./views/job/enterpriseLegal"),
          name: "合规企业管理",
          meta: { requireAuth: true }
        },
        {
          path: "/enterpriseMerge",
          component: () => import("./views/job/enterpriseMerge"),
          name: "企业合并",
          meta: { requireAuth: true }
        },
        {
          path: "/jobList",
          component: () => import("./views/job/jobList"),
          name: "岗位发布列表",
          meta: { requireAuth: true }
        },
        {
          path: "/enList",
          component: () => import("./views/job/enList"),
          name: "学校新增企业列表",
          meta: { requireAuth: true }
        },
        {
          path: "/enAdd",
          component: () => import("./views/job/enAdd"),
          name: "学校新增企业",
          meta: { requireAuth: true }
        },
        {
          path: "/reqList",
          component: () => import("./views/cooperation/reqList"),
          name: "合作需求",
          meta: { requireAuth: true }
        },
        {
          path: "/reqAdd",
          component: () => import("./views/cooperation/reqAdd"),
          name: "合作需求发布",
          meta: { requireAuth: true }
        },
        {
          path: "/schInfo",
          component: () => import("./views/school/schInfo"),
          name: "学校简介",
          meta: { requireAuth: true }
        },
        {
          path: "/invite",
          component: () => import("./views/school/invite"),
          name: "邀请合作企业",
          meta: { requireAuth: true }
        },
        {
          path: "/baseList",
          component: () => import("./views/base/baseList"),
          name: "实训基地列表",
          meta: { requireAuth: true }
        },
        {
          path: "/internBase",
          component: () => import("./views/base/internBase"),
          name: "实习基地管理",
          meta: { requireAuth: true }
        },
        {
          path: "/xwsxjdgl",
          component: () => import("./views/base/xwsxjdgl"),
          name: "校外实习基地管理",
          meta: { requireAuth: true }
        },
        {
          path: "/xnsxjd24",
          component: () => import("./views/base/xnsxjd24"),
          name: "校内实习基地管理",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseWhtl",
          component: () => import("./views/base/internBaseWhtl"),
          name: "实习基地管理",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseSzyy",
          component: () => import("./views/base/internBaseSzyy"),
          name: "实习基地管理",
          meta: { requireAuth: true }
        },
        {
          path: "/internMajorCount",
          component: () => import("./views/base/internMajorCount"),
          name: "专业建设统计",
          meta: { requireAuth: true }
        },
        {
          path: "/menuMange",
          component: () => import("./views/set/menuMange"),
          name: "菜单管理",
          meta: { requireAuth: true }
        },
        {
          path: "/postManage",
          component: () => import("./views/set/postManage"),
          name: "负责人职位分配",
          meta: { requireAuth: true }
        },
        {
          path: "/expireManage",
          component: () => import("./views/base/expireManage"),
          name: "负责人职位分配",
          meta: { requireAuth: true }
        },
        {
          path: "/processManage",
          component: () => import("./views/base/processManage"),
          name: "基地过程管理记录",
          meta: { requireAuth: true }
        },
        {
          path: "/processManage",
          component: () => import("./views/base/processManage"),
          name: "基地过程管理记录",
          meta: { requireAuth: true }
        },
        {
          path: "/roleMange",
          component: () => import("./views/set/roleMange"),
          name: "角色管理",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseSzyyZyfzr",
          component: () => import("./views/base/internBaseSzyyZyfzr"),
          name: "实习基地管理",
          meta: { requireAuth: true }
        },
        {
          path: "/sxjdjysh2023",
          component: () => import("./views/base/sxjdjysh2023"),
          name: "实习基地解约审核",
          meta: { requireAuth: true }
        },
        {
          path: "/operPage",
          component: () => import("./views/base/operPage"),
          name: "实习基地审核",
          meta: { requireAuth: true }
        },
        {
          path: "/operPageHzkj",
          component: () => import("./views/base/operPageHzkj"),
          name: "实习基地审核",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseSzyyBuild",
          component: () => import("./views/base/internBaseSzyyBuild"),
          name: "实习基地管理申报",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseCount",
          component: () => import("./views/base/internBaseCount"),
          name: "实习基地统计",
          meta: { requireAuth: true }
        },
        {
          path: "/sxjdqddy",
          component: () => import("./views/qddy/sxjdqddy"),
          name: "实习基地名单",
          meta: { requireAuth: true }
        },
        {
          path: "/sxjdhtxy",
          component: () => import("./views/htxy/sxjdhtxy"),
          name: "实习基地名单",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseCountSzyy",
          component: () => import("./views/base/internBaseCountSzyy"),
          name: "实习基地统计",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseCountSzyy1",
          component: () => import("./views/base/internBaseCountSzyy1"),
          name: "国家数据平台统计",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseCountSzyy2",
          component: () => import("./views/base/internBaseCountSzyy2"),
          name: "国家创新调查用数据",
          meta: { requireAuth: true }
        },
        {
          path: "/internBaseBuild",
          component: () => import("./views/base/internBaseBuild"),
          name: "实习基地建设申报",
          meta: { requireAuth: true }
        },
        {
          path: "/baseStudents",
          component: () => import("./views/base/baseStudents"),
          name: "实习基地学生情况",
          meta: { requireAuth: true }
        },
        {
          path: "/baseAdd",
          component: () => import("./views/base/baseAdd"),
          name: "新增实训基地",
          meta: { requireAuth: true }
        },
        {
          path: '/job-fair-company',
          component: () => import('./views/job-fair/company'),
        },
        {
          path: '/sxhCompany',
          component: () => import('./views/job-fair/sxhCompany'),
        },
        {
          path: '/sxhInviteList',
          component: () => import('./views/job-fair/sxhInviteList'),
        },
        {
          path: '/sxhInviteInfo',
          component: () => import('./views/job-fair/sxhInviteInfo'),
        },
        {
          path: '/job-fair-job',
          component: () => import('./views/job-fair/job'),
        },
        {
          path: '/job-fair-stat',
          component: () => import('./views/job-fair/Stat'),
        },
        {
          path: '/student-send',
          component: () => import('./views/student-send/student-send'),
        },
        {
          path: '/school-config',
          component: () => import('./views/job-fair/schoolConfig'),
        },
        {
          path: '/enterprise-config',
          component: () => import('./views/job-fair/sxhEnterprise'),
        },
        {
          path: "/enterprise-review",
          component: () => import("./views/school/enterprise-review"),
        },
        {
          path: "/sxhHireCount",
          component: () => import("./views/job-fair/sxhHireCount"),
        },
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});