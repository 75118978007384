import api from "../axios";
import {baseUrl} from "../baseUrl";

export default {
  // 校企合作申请列表
  getType(params) {
    return api.getParams(baseUrl + "api/sys/list", params);
  },
  // 获取企业行业信息
  getTrade() {
    return api.get(baseUrl + "api/sys/list/trade");
  },
  // 获取省级列表
  getProvince() {
    return api.get(baseUrl + "api/common/province/list");
  }
};