
import api from "../axios";
import {baseUrl} from "../baseUrl";

export default {
  // 合作需求列表
  reqList(params) {
    return api.post(baseUrl + "api/school/recruit/plan/list", params);
  },
  // 合作需求发布
  reqAdd(params) {
    return api.post(baseUrl + "api/school/recruit/plan/create", params);
  }
};