import axios from "axios";
import Qs from "qs";
import Element from "element-ui";
import Vue from "vue";

// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading;
function startLoading() {
  loading = Vue.prototype.$loading({
    lock: true,
    text: "玩命加载中...",
    target: document.querySelector(".loading-area") //设置加载动画区域
  });
}
function endLoading() {
  loading.close();
}

//声明一个对象用于存储请求个数
let needLoadingRequestCount = 0;
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}
function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}

// 创建 axios 实例
let instance = axios.create({
  timeout: 60000,
  withCredentials: true // 允许携带cookie
});

//允许在请求数据发送到服务器之前对其进行更改,这只适用于请求方法'PUT'，'POST'和'PATCH'
instance.defaults.transformRequest = [
  function(data) {
    return Qs.stringify({
      ...data
    });
  }
];

instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    showFullScreenLoading();
    return config;
  },
  error => {
    tryHideFullScreenLoading();
    Element.Message.error({ message: "请求超时!" });
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    tryHideFullScreenLoading();
    let { data } = response;
    if (data.code !== 0) {
      Element.Message({
        type: 'error',
        message: data.msg
      })
    }
    if (data.code === 401) {
      window.location.href = '/#/login'
    }
    return data;
  },
  error => {
    tryHideFullScreenLoading();
    Element.Message({
      type: 'error',
      message: error.message
    })
  }
);

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default {
  // 以query形式传参
  get(url, params, headers) {
    let options = {};

    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
    }
    return instance.get(url, options);
  },
  // 以params形式传参
  getParams(url, params) {
    return instance.get(url + "/" + params);
  },
  post(url, params) {
    // let options = {};
    // if (params) {
    //   options.params = params;
    // }
    // if (headers) {
    //   options.headers = headers;
    // }
    return instance.post(url, params);
  },
  put(url, params, headers) {
    let options = {};

    if (headers) {
      options.headers = headers;
    }
    return instance.put(url, params, options);
  },
  delete(url, params, headers) {
    let options = {};

    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
    }
    return instance.delete(url, options);
  }
};