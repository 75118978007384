import api from "../axios";
import {baseUrl} from "../baseUrl";

export default {
  // 省
  getProvince(params) {
    return api.get(baseUrl + "api/common/province/list", params);
  },
  // 市
  getCity(params) {
    return api.getParams(baseUrl + "api/common/city/list", params);
  },
  // 区
  getArea(params) {
    return api.getParams(baseUrl + "api/common/county/list", params);
  },
  getXixunProvince() {
    return api.get(baseUrl + `api/common/area/xixun/province/list`)
  },
  getXixunCity(provinceId) {
    return api.get(baseUrl + `api/common/area/xixun/city/list/${provinceId}`)
  },
  getXixunCounty(cityId) {
    return api.get(baseUrl + `api/common/area/xixun/county/list/${cityId}`)
  },
  getDataByUrl(url,method,params={}){
    return api[method](`${baseUrl+url}`,params);
  }
};
