import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "babel-polyfill";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
import "./assets/css/reset.scss";
import "./assets/css/deep.scss";
import api from "./api/apiList";  // axios封装
// import "./assets/iconfont/iconfont.css";  // iconfont
import * as custom from "./assets/js/filters";  // 过滤器
import Cookies from "js-cookie";  // cookie

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key]);
});


// 引入echarts
// import echarts from "echarts";
// Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api
Vue.use(ElementUI);

// Vue.use(Viewer);//图片预览

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (Cookies.get("qxy_token")) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    this.$store.dispatch('getFoods');
  }
}).$mount("#app");

// Viewer.setDefaults({
//   'inline':false,
//   'button':true, //右上角按钮
//   "navbar": true, //底部缩略图
//   "title": true, //当前图片标题
//   "toolbar": true, //底部工具栏
//   "tooltip": true, //显示缩放百分比
//   "movable": true, //是否可以移动
//   "zoomable": true, //是否可以缩放
//   "rotatable": true, //是否可旋转
//   "scalable": true, //是否可翻转
//   "transition": true, //使用 CSS3 过度
//   "fullscreen": true, //播放时是否全屏
//   "keyboard": true, //是否支持键盘
//   "url": "data-source",
//   ready: function (e) {
//     //console.log(e.type,'组件以初始化');
//   },
//   show: function (e) {
//     //console.log(e.type,'图片显示开始');
//   },
//   shown: function (e) {
//     //console.log(e.type,'图片显示结束');
//   },
//   hide: function (e) {
//     //console.log(e.type,'图片隐藏完成');
//   },
//   hidden: function (e) {
//     //console.log(e.type,'图片隐藏结束');
//   },
//   view: function (e) {
//     //console.log(e.type,'视图开始');
//   },
//   viewed: function (e) {
//     //console.log(e.type,'视图结束');
//   },
//   zoom: function (e) {
//     //console.log(e.type,'图片缩放开始');
//   },
//   zoomed: function (e) {
//     //console.log(e.type,'图片缩放结束');
//   }
// });