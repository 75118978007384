let baseUrl = '' // 接口URL
let pageUrl = '' // 企业端网址

switch (location.hostname) {
    // 本地环境url
    // case 'localhost':
    //     // baseUrl = "https://test_api.zjtoutiao.cn/";
    //     baseUrl = "https://api.xixunyun.cn/"; 
    //     pageUrl = 'https://test_enterprise.zjtoutiao.cn'
    //     break;
    // 测试环境url
    case 'test_school.zjtoutiao.cn':
        baseUrl = "https://test_api.zjtoutiao.cn/";
        pageUrl = 'https://test_enterprise.zjtoutiao.cn'
        break;
    // 正式环境url
    case 'school.xixunyun.cn':
        baseUrl = "https://api.xixunyun.cn/";
        pageUrl = 'https://qxiaoyun.com'
        break;
    case 'yunnan_school.xixunyun.cn':
        baseUrl = "https://api.xixunyun.cn/";
        pageUrl = 'https://qxiaoyun.com'
        break;
    case 'hzschool.qxiaoyun.com':
        baseUrl = "https://hzapi.qxiaoyun.com/";
        pageUrl = 'https://hzschool.qxiaoyun.com'
        break;
    case 'dgsx.gfxy.com':
        baseUrl = "https://dgsx.gfxy.com/api/qxyapi/";
        pageUrl = 'https://dgsx.gfxy.com/qxyweb'
        break;
    case 'jwcsx.nxnu.edu.cn':
        baseUrl = "https://jwcsx.nxnu.edu.cn/api/qxyapi/";
        pageUrl = 'https://jwcsx.nxnu.edu.cn/qxyweb'
        break;
    case 'sx.tec.suda.edu.cn':
        baseUrl = "https://sx.tec.suda.edu.cn/api/qxyapi/";
        pageUrl = 'https://sx.tec.suda.edu.cn/qxyweb'
        break;
    case 'localhost':
        baseUrl = "https://api.qxiaoyun.com/";
        pageUrl = 'https://qxiaoyun.com'
        break;
    case 'lnsxglxt.lnrc.com.cn':
        baseUrl = "https://lnsxglxt.lnrc.com.cn/api/qxyapi/";
        pageUrl = 'https://lnsxglxt.lnrc.com.cn/qxyweb'
        break;
    case 'dgsx.cqcfe.com':
        baseUrl = "https://dgsx.cqcfe.com/api/qxyapi/";
        pageUrl = 'https://dgsx.cqcfe.com/qxyweb'
        break;
    case '58.56.149.222':
        baseUrl = "http://58.56.149.222:6080/api/qxyapi/";
        pageUrl = 'http://58.56.149.222:6080/qxyweb'
        break;
    default:
        if (localStorage.getItem("qxiaoyun_api") && localStorage.getItem("qxiaoyun_api").indexOf("http") > -1) {
            baseUrl = localStorage.getItem("qxiaoyun_api")
        } else {
            baseUrl = "https://api.qxiaoyun.com/";
        }
        pageUrl = 'https://qxiaoyun.com'
        let school_id = localStorage.getItem("school_id")
        if (school_id == 675) {
            baseUrl = "https://dgsx.gfxy.com/api/qxyapi/";
            pageUrl = 'https://qxiaoyun.com'
        }

}

export {
    baseUrl, pageUrl
};