import api from "../axios";
import {baseUrl} from "../baseUrl";

export default {
  // 学校查看企业发布职位
  jobList(params) {
    return api.post(baseUrl + "api/school/enterprise/job/list", params);
  },
  // 学校审核企业发布职位
  jobAudit(jobId,params) {
    return api.post(baseUrl + `api/school/enterprise/job/audit/${jobId}`, params);
  },
  // 学校新增企业
  enAdd(params) {
    return api.post(baseUrl + "api/school/enterprise/record/create", params);
  },
  // 学校新增企业列表
  enList(params) {
    return api.post(baseUrl + "api/school/enterprise/xixun/list", params);
  },
  //学校发布职位
  jobAdd(params) {
    return api.post(baseUrl + "api/school/job/create", params);
  },
  //学校发布职位列表
  schjobList(params) {
    return api.post(baseUrl + "api/school/job/list", params);
  },
  queryCompany(params) {
    return api.post(baseUrl + "api/v3/schoolToken/Nmgjd/queryCompany", params);
  },
  delJob(jobId) {
    return api.delete(baseUrl + `api/school/job/delete/${jobId}`);
  },
  queryCompany(params) {
    return api.post(baseUrl + "api/v3/schoolToken/Nmgjd/queryCompany", params);
  },
  creatCompany(params) {
    return api.post(baseUrl + "api/v3/schoolToken/Nmgjd/addCompany", params);
  },
  updateCompany(params) {
    return api.post(baseUrl + "api/v3/schoolToken/Nmgjd/editCompany", params);
  },
  delCompany(params) {
    return api.post(baseUrl + `api/v3/schoolToken/Nmgjd/deleteCompany`,params);
  },
  // 企业合并
  mergeJob(params){
    return api.post(baseUrl + `api/school/enterprise/merge`,params);
  },
  // 学生投递
  getStudentNoSend(params){
    return api.get(baseUrl + `api/school/sxh/student/not_apply/list`,params);
  },
  // 获取未投递学生
  getStudentSend(params){
    return api.post(baseUrl + `api/school/sxh/apply/list`,params);
  },
  // 获取确认或未确认入职学生
  getStudentEntry(params){
    return api.post(baseUrl + `api/school/sxh/student/apply_confirm_status/list`,params);
  },
  // 学生投递
  updateJobOrder(jobId,order){
    return api.get(baseUrl + `api/school/sxh/job/order/${jobId}/${order}`);
  },
  // 岗位上线
  jobUpPass(data){
    return api.post(baseUrl + `api/school/job/bulk/set`,data);
  },
  jobCopySxh(data){
    return api.post(baseUrl + `api/school/job/copyToSxh/${data.id}`);
  },
  feedbackList(params) {
    return api.post(baseUrl + "api/school/feedback/query", params);
  },
  GetHires(params) {
    return api.post(baseUrl + "api/school/enterprise/hire_statics", params);
  },
};