// 已邀请的企业
import api from "../axios";
import {baseUrl} from "../baseUrl";

export default {
  // 列表
  list() {
    return api.get(baseUrl + 'api/school/invite/enterprise/list')
  },
  // 获取营业执照
  licence(params) {
    return api.getParams(baseUrl + 'api/school/enterprise/licence/get', params)
  },
};