// 学校信息模块
import api from "../axios";
import { baseUrl } from "../baseUrl";

export default {
  // 获取学校列表
  getSchoolList() {
    return api.get(baseUrl + "api/school/list/all");
  },
  getMajorList() {
    return api.get(baseUrl + `api/school/majors/list`)
  },
  // 双选会更换专业
  changeMajors(params) {
    return api.post(baseUrl + `api/school/sxh/major/update`,params)
  },
  // 添加/更改学校简介
  addDescribe(params) {
    return api.post(baseUrl + "api/school/describe/addOrUpdate", params);
  },
  // 获取学校简介
  getDescribe() {
    return api.get(baseUrl + "api/school/describe/get");
  },
  // 获取学校配置信息
  config(schoolId) {
    return api.get(baseUrl + `api/school/config/get/${schoolId}`);
  },
  updateConfig(schoolId, params) {
    return api.post(baseUrl + `api/school/config/update/${schoolId}`, params)
  },
  updateJob(id, params) {
    return api.post(baseUrl + `api/school/job/update/${id}`, params)
  },
  //获取学校班级列表
  getClassList() {
    return api.get(baseUrl + "api/school/class/list");
  },
  //获取学院列表
  getXueyuanList() {
    return api.get(baseUrl + "api/school/institute/list");
  },
  //获取学院双选会列表
  getSxhConfigList() {
    return api.get(baseUrl + "api/school/sxh/institute/conf");
  },
  //设置学院双选会配置
  setSxhConfigList(params) {
    return api.post(baseUrl + "api/school/sxh/institute/conf",params);
  },
  //获取学院列表
  getInstituteList() {
    return api.get(baseUrl + "api/school/teacher/institute/get");
  },
  //面试通过
  passNopassSubmit(id,status) {
    return api.get(baseUrl + `api/school/sxh/job_apply/status/set/${id}/${status}`);
  },
  //校获取企业对学生的评价
  getEnterpriseAdvise(params) {
    return api.post(baseUrl + "api/school/student_review/list",params);
  },
  getSchoolConfig(schoolId) {
    return api.get(baseUrl + `api/enterprise/school/config/get/${schoolId}`);
  },
  setSchoolConfig(schoolId,params) {
    return api.post(baseUrl + `api/school/config/set/${schoolId}`,params);
  },
  // //审核
  // passNopassAudio(id,status) {
  //   return api.get(baseUrl + `api/school/sxh/job_apply/status/set/{apply_id}/{status}`);
  // },
};